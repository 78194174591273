.navbar {
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.05);
    padding: 0px;
    position: sticky;
    top: 0px;
    z-index: 999;

    .navbar-brand {
        img {
            height: 70px;
            width: auto;

            @media only screen and (max-width: 1199.98px) {
                height: 50px;
            }
        }

    }

    .navbar-nav .nav-link {
        font-size: 1rem;
        // font-weight: 500;
        font-weight: 600;
        padding: 30px 10px;
        color: $default  !important;

        &:hover,
        &:active,
        &:focus {
            color: $primary  !important;
        }

        @media only screen and (max-width: 1199.98px) {
            font-size: 0.938rem;
            padding: 30px 5px;
        }

        @media only screen and (max-width: 991.98px) {
            padding: 10px 15px;
            border-bottom: solid 1px $light_dark;
            @include transition(.2s);
        }
    }

    .dropdown-toggle::after {
        content: '\f107';
        font-family: "mhc-icon";
        font-weight: normal;
        font-style: normal;
        margin: 0px 0px 0px 5px;
        text-decoration: none;
        border: 0px !important;
        vertical-align: 0em;

        @media only screen and (max-width: 991.98px) {
            position: absolute;
            right: 5px;
            content: '\e801';
        }
    }

    .icon-user-_1_::before {
        font-family: "mhc-icon";
        font-weight: normal;
        font-style: normal;
        margin: 0px 0px 0px 0px;
        text-decoration: none;
        border: 0px !important;
        vertical-align: 0em;
    }

    .nav-reigster-form {
        min-width: 400px;
        padding: 15px;
        color: $default  !important;

        @media only screen and (max-width: 991.98px) {
            min-width: 100%;
            border: solid 1px $white_mute;
        }

        a {
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
        }

        .form-select,
        .form-control {
            padding: 0.375rem 0.75rem !important;
            height: calc(1.6em + 0.75rem + 2px);
            border-color: $border-color;
            border-radius: 10px;

            &:hover,
            &:active,
            &:focus {
                border-color: $border-color;
            }
        }

        .form-select {
            border-bottom-right-radius: 0px;
            border-top-right-radius: 0px;

            &:hover,
            &:active,
            &:focus {
                box-shadow: none;
            }
        }

        .form-control {
            border-bottom-left-radius: 0px;
            border-top-left-radius: 0px;
        }

        .fs-7 {
            font-size: 0.9rem;
        }

        @media only screen and (max-width: 575.98px) {
            p {
                font-size: 0.938rem;
            }

            .w-25 {
                width: 30% !important;
            }
        }

        .enter-otp {
            @include transition(.2s);

            .input-group-text {
                background: $transparent  !important;
            }

            .rounded-start {
                border-bottom-left-radius: 10px !important;
                border-top-left-radius: 10px !important;
            }

            .rounded-end {
                border-top-right-radius: 010px !important;
                border-bottom-right-radius: 10px !important;
            }
        }

    }

    .nav-user-profile {
        li {
            &:active {
                background-color: $white  !important;
                color: $default;
            }

            cursor: pointer;

            .mhc-icon {
                font-size: 1rem;
                padding-right: 5px;
            }

            .form-check-label {
                font-size: 0.875rem;
                padding-left: 4px;
            }

        }

    }

    .dropdown-item.active,
    .dropdown-item:active {
        background-color: #ffffff;
        color: $default;
    }

    .dropdown-item:focus,
    .dropdown-item:hover {
        background-color: #ffffff;
        color: $primary;
    }

    @media only screen and (min-width: 992px) {
        .navbar-nav li.dropdown:hover ul.dropdown-menu {
            display: block;
        }

        .dropdown-menu {
            top: 80px;
            border-color: $bg_light;
            box-shadow: 0px 2px 3px rgb(0 0 0 / 16%);

            .dropdown-item {
                color: lighten($default, 15%);

                &:hover,
                &:active,
                &:focus {
                    color: lighten($primary, 10%);
                    background-color: transparent;
                }

                a {
                    color: lighten($default, 15%);
                    text-decoration: none;

                    &:hover,
                    &:active,
                    &:focus {
                        color: lighten($primary, 10%);

                    }
                }
            }


        }

    }

    .dropdown-menu {
        @media only screen and (max-width: 991.98px) {
            border-width: 0px;

            .dropdown-item {
                border-bottom: solid 1px $border-color;
                margin: 0px 15px;
                width: calc(100% - 30px);
                color: lighten($default, 15%);

                a {
                    color: lighten($default, 15%);
                    text-decoration: none;

                    &:hover,
                    &:active,
                    &:focus {
                        color: lighten($primary, 10%);

                    }
                }

                &:last-child {
                    border-bottom: solid 0px;
                }
            }
        }
    }

    .mob-phone-call {
        width: 25px;
        position: absolute;
        right: 64px;
        top: 22px;

        @media only screen and (max-width: 575.98px) {
            right: 75px;

        }
    }

    .navbar-toggler {
        padding: 0px;
        font-size: 1.25rem;
        line-height: 1;
        background-color: transparent;
        border: 0px solid transparent;
        border-radius: 0px;
        @include transition(.2s);

        &:focus {
            box-shadow: none;
        }

        img {
            width: 20px;
        }
    }

}

.dropdown-right {
    right: 0px !important;
}

.loginUser {
    height: 15px;
    width: 15px;
    position: relative;
    bottom: 2px;
}


@media only screen and (max-width: 991.98px) {
    .navbar-nav {
        width: 100%;
        height: calc(100% - 0px);
        top: 60px;
        position: fixed;
        left: unset;
        right: -1000px;
        z-index: 999;
        opacity: 0;
        overflow-y: hidden;
        padding: 0;
        border-top: solid 1px rgba(255, 255, 255, .1);
        background: $bg_light;

        @include transition(.5s);
    }

    .mob-submenu {
        .navbar-nav {
            right: 0;
            opacity: 1;

            li {
                width: 720px;
                margin: 0px auto;

                @media only screen and (max-width: 767.98px) {
                    width: 100%;
                }

                @media only screen and (max-width: 575.98px) {
                    width: 100%;
                    margin: 0px;
                }
            }

        }
    }



}

@media only screen and (max-width: 991.98px) {
    .menu-toggle {
        display: block;
        font-size: 0;
        border: none;
        width: 32px;
        cursor: pointer;
        position: absolute;
        right: 0px;
        top: 19px;
        z-index: 11;
        @include transition(.2s);

        @media only screen and (max-width: 575.98px) {
            right: 20px;
        }

        .line-one,
        .line-two,
        .line-three {
            backface-visibility: hidden;
            display: block;
            height: 3px;
            margin: 5px auto;
            width: 100%;
            background: $default;
            @include transition(.2s);
        }

        &.on {
            .line-one {
                transform: rotate(-45deg) translate(-5px, 5px);
            }

            .line-two {
                opacity: 0;
            }

            .line-three {
                transform: rotate(45deg) translate(-6px, -6px);
            }
        }
    }

    .mobsubnone {
        height: 0px;
        @include transition(.2s);
        margin-top: -15px;
        opacity: 0;
        display: none;
    }

    .mobsublock {
        top: 0px;
        opacity: 1;
        display: block;
        margin-top: 0px;
        @include transition(.2s);
    }
}

.nav-service .icon-plus:before,
.nav-service .icon-minus::before {
    font-family: "mhc-icon";
    font-size: 18px;
}

// #######################################footer cssss############################################


footer {

    padding: 0 0 30px 0;
    color: #212529;
    font-size: 14px;
    background: #0B0D17;
    background-image: url(../images/footer-background.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;

    h2 {
        font-size: 16px;
        color: $white;
        padding-bottom: 2px;
        margin: 0px;
    }

    .list-unstyled li {
        padding: 5px 0px;
    }

    .list-unstyled li a {
        color: $text_muted;
        text-decoration: none;

        &:hover {
            text-decoration: none;
            color: $white;
        }
    }

    .list-inline li span {
        color: $white;
    }

    .list-inline li .icon {
        padding: 0px 5px;
    }

    .list-inline li .icon img {
        width: 30px;
        height: 30px;
    }

    .list-inline li a.download {
        padding: 0px 10px;

        @media only screen and (max-width: 991.98px) {
            padding: 0px 5px;
        }
    }

    .list-inline li a.download img {
        height: 35px;

        @media only screen and (max-width: 991.98px) {
            height: 31px;
        }
    }


}

.copy-right {
    background: #272f3e;

    @media only screen and (max-width: 991.98px) {
        margin-bottom: 78px;
    }

    @media only screen and (max-width: 575.98px) {
        margin-bottom: 68px;
    }

    p {
        margin: 0px;
        color: $white;
        text-align: center;
        padding: 10px 0px;
        font-size: 0.875rem;

        @media only screen and (max-width: 991.98px) {
            font-size: 0.813rem;
        }

        @media only screen and (max-width: 575.98px) {
            font-size: 0.813rem;
        }
    }
}

.mobsubnone1 {
    height: 0px;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    margin-top: -15px;
    opacity: 0;
    display: none;
}

.mobsublock1 {
    top: 0px;
    opacity: 1;
    display: block;
    margin-top: 0px;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
}

.navlocation {
    border: solid 1px $light_dark;
}

.dropdown-menu {
    min-width: 12rem;
}

.collapse-switch {

    opacity: 0;
    height: 0px;
    @include transition(.2s);
    overflow: auto;

    &.show {
        height: 170px;
        opacity: 1;

    }

    &.hide {
        height: 0px;
        opacity: 0;
    }
}